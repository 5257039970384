<template>
  <div id="productionBase">
    <!-- 头部组件 -->
    <headPage />
    <!-- 轮播图 -->
    <carousel />

    <div class="container-2">
      <div class="title fw-600">让教育回归本质&nbsp;&nbsp;用心打造精品课</div>

      <div class="cont m-40">
        <div class="item">
          <img
            class="img"
            src="../../../assets/loginIndex/productionBase_3.png"
          />
          <div>
            <div class="t-1">课程方向调研</div>
            <div class="t-2">
              行业/市场/用户/竞品四维调研<br />打造最优课程内容
            </div>
          </div>
        </div>

        <div class="item">
          <img
            class="img"
            src="../../../assets/loginIndex/productionBase_4.png"
          />
          <div>
            <div class="t-1">知识地图确定</div>
            <div class="t-2">
              <p>网络课程海量收录</p>
              <p>形成课程知识地图</p>
            </div>
          </div>
        </div>

        <div class="item">
          <img
            class="img"
            src="../../../assets/loginIndex/productionBase_5.png"
          />
          <div>
            <div class="t-1">授课师资确定</div>
            <div class="t-2">
              <p>明确课程制作方向</p>
              <p>聘请专业教研团队</p>
            </div>
          </div>
        </div>
      </div>

      <div class="cont m-16">
        <div class="item">
          <img
            class="img"
            src="../../../assets/loginIndex/productionBase_6.png"
          />
          <div>
            <div class="t-1">教研教学设计</div>
            <div class="t-2">分解教学目标，形成课程体系<br />提升学习效率</div>
          </div>
        </div>

        <div class="item">
          <img
            class="img"
            src="../../../assets/loginIndex/productionBase_7.png"
          />
          <div>
            <div class="t-1">优质课程录制</div>
            <div class="t-2">
              <p>重新定义课程制作形式</p>
              <p>打造有质感的精品好课</p>
            </div>
          </div>
        </div>

        <div class="item">
          <img
            class="img"
            src="../../../assets/loginIndex/productionBase_8.png"
          />
          <div>
            <div class="t-1">严格课程评审</div>
            <div class="t-2">
              <p>双重审核机制把关</p>
              <p>保证内容准确合规</p>
            </div>
          </div>
        </div>
      </div>

      <div class="title fw-600">开放、共享，专注研发</div>

      <div class="text-1">
        平台现有2.9w+课时的在线视频课程，包含150余个职业工种的职业技能培训，42个行业的安全生产培训，可全方位、定制化的为企业及个人提供专业、丰富的课程资源服务。
      </div>

      <div class="cont-2">
        <div class="item">
          <img src="../../../assets/loginIndex/productionBase_9.png" />
          <div class="text">
            <p>聘请行业专家</p>
            <p>专注课程研发</p>
          </div>
        </div>

        <div class="item">
          <img src="../../../assets/loginIndex/productionBase_10.png" />
          <div class="text">
            <p>开放课程共享</p>
            <p>覆盖全域好课</p>
          </div>
        </div>

        <div class="item">
          <img src="../../../assets/loginIndex/productionBase_11.png" />
          <div class="text">
            <p>独立制作团队</p>
            <p>保障课程输出</p>
          </div>
        </div>

        <div class="item">
          <img src="../../../assets/loginIndex/productionBase_12.png" />
          <div class="text">
            <p>依托新兴技术</p>
            <p>丰富学习体验</p>
          </div>
        </div>

        <div class="item">
          <img src="../../../assets/loginIndex/productionBase_13.png" />
          <div class="text">
            <p>精准用户分析</p>
            <p>持续内容更新</p>
          </div>
        </div>

        <div class="item">
          <img src="../../../assets/loginIndex/productionBase_14.png" />
          <div class="text">
            <p>权威题库资源</p>
            <p>助力科学应试</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="title fw-600">专业制作团队</div>
      <div class="text-1">
        安知平台现已建成课程制作中心，300平米专属课程拍摄场地，200+资深名师入驻，优良的制作设备，创新的课程研发流程，精湛的后期技术保障，可一站式满足企业各类培训需求。
      </div>
      <div class="container_b">
        <div class="l">
          <img src="../../../assets/loginIndex/productionBaseL.png" alt="" />
          <div class="shell">
            <div class="l_1">北戴河课程制作中心</div>
            <div class="l_2">
              数百平米录影棚、专业录音设备、精美声学环境，多处实景组成，根据需求实现多场景展现，轻松实现现场互动。
            </div>
          </div>
        </div>
        <div class="r">
          <div class="r_1"></div>
          <div class="r_2"></div>
        </div>
      </div>
    </div>
    <!-- 底部组件 -->
    <bottomPage />
  </div>
</template>

<script>
import headPage from "../../wrongAuthenticatePage/headPage.vue";
import bottomPage from "../../wrongAuthenticatePage/bottomPage.vue";
import carousel from "../../wrongAuthenticatePage/carousel.vue";

export default {
  name: "wrongAuthenticatePage_productionBase",
  components: {
    headPage,
    bottomPage,
    carousel,
  },
  data() {
    return {
      bannerList: [], // 轮播图列表
      // 专业制作团队数组
      list_index: "",
      listData: [
        {
          img: require("@/assets/loginIndex/productionBase_1.png"),
          name: "陈浩",
        },
        {
          img: require("@/assets/loginIndex/productionBase_2.png"),
          name: "高强",
        },
        {
          img: require("@/assets/loginIndex/productionBase_1.png"),
          name: "陈浩",
        },
        {
          img: require("@/assets/loginIndex/productionBase_2.png"),
          name: "高强",
        },
      ],
    };
  },
  created() {
  },
  methods: {
    mouseOver(e) {
      this.list_index = e;
    },
    mouseLeave(e) {
      this.list_index = "";
    },
   
  },
};
</script>

<style lang="less">
#productionBase {
  height: 100vh;
  overflow-y: scroll;
  background-color: #f8fafd;

  .container {
    width: 1168px;
    margin: 0 auto;
    padding: 60px 0;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      color: #3f4652;
      font-size: 34px;
      text-align: center;
    }
    .text-1 {
      width: 1168px;
      text-align: center;
      font-size: 17px;
      margin-top: 16px;
      color: #5c6b84;
      padding: 0 75px;
    }
    .container_b {
      width: 100%;
      overflow: hidden;
      margin-top: 40px;
      .l {
        width: 802px;
        height: 470px;
        float: left;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
        .shell {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
          display: none;
          .l_1 {
            font-size: 30px;
            color: #fff;
            text-align: center;
            margin-top: 180px;
          }
          .l_2 {
            width: 70%;
            font-size: 17px;
            color: #fff;
            margin: auto;
            margin-top: 20px;
            text-indent: 2em;
          }
        }

        &:hover .shell {
          display: block;
        }
      }
      .r {
        width: 355px;
        height: 230px;
        float: right;
        .r_1 {
          width: 100%;
          height: 230px;
          background: url("../../../assets/loginIndex/productionBaseR_1.png")
            center center no-repeat;
          background-size: 100% 100%;
        }
        .r_2 {
          width: 100%;
          height: 230px;
          margin-top: 10px;
          background: url("../../../assets/loginIndex/productionBaseR_2.png")
            center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .container-2 {
    width: 1168px;
    margin: 0 auto;
    padding-bottom: 60px;
    .m-40 {
      margin-top: 40px;
    }
    .m-16 {
      margin-top: 16px;
    }

    .title {
      margin-top: 60px;
      color: #3f4652;
      font-size: 34px;
      text-align: center;
    }

    .cont {
      display: flex;
      justify-content: space-between;
      .item {
        display: flex;
        width: 378px;
        height: 146px;
        background: #ffffff;

        padding: 30px 20px;
        box-sizing: border-box;
        .img {
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .t-1 {
          font-size: 20px;
          color: #3f4652;
          font-weight: 500;
        }
        .t-2 {
          font-size: 14px;
          color: #5c6b84;
          margin-top: 16px;
          font-weight: 400;
        }
      }
      .item:hover {
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }

    .text-1 {
      font-size: 17px;
      color: #5c6b84;
      margin-top: 16px;
      text-align: center;
      padding: 0 75px;
    }

    .cont-2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 36px;

      .item {
        width: 180px;
        height: 299px;
        border-radius: 8rpx;
        overflow: hidden;

        .text {
          height: 100px;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .item:hover {
        box-shadow: 0px 0px 50px 0px rgba(87, 174, 244, 0.16);
      }
    }
  }
}
</style>
