<template>
  <!-- 新 - 首页 - 底部组件 -->
  <div class="bottomPage">
    <div class="shell">
      <div class="shell_1">
        <img src="../../assets/loginIndexNew/bottomLogo.png" alt="" />
        <p>技术支持：北京晟融信息技术有限公司</p>
        <p>售前咨询：010-62927526 18601015296</p>
        <p>售后服务：0335-8511096</p>
        <p>电子邮箱：support@bjsrxx.com</p>
        <p>公司地址：北京市通州区江米店街2号院5号楼28层2821</p>
      </div>
      <!--<div class="shell_2">-->
      <!--  <img src="../../assets/loginIndex/bottom_xcx.png" alt="" />-->
      <!--  <p class="p_1">安知小程序</p>-->
      <!--  <p class="p_2">学知识取证书</p>-->
      <!--</div>-->
      <div class="shell_3">
        <img src="../../assets/loginIndex/bottom_ewm.png" alt="" />
        <p class="p_1">安知app</p>
        <p class="p_2">职业技能培训</p>
      </div>
      <!--<div class="shell_4">-->
      <!--  <img src="../../assets/loginIndex/bottom_gzh.png" alt="" />-->
      <!--  <p class="p_1">安知公众号</p>-->
      <!--  <p class="p_2">关注行业动态</p>-->
      <!--</div>-->
    </div>
    <div class="loginFoot">
      <span>
        {{ new Date().getFullYear() }} © 北京晟融信息技术有限公司
        <span
          @click="goToIp"
          style="cursor: pointer; font-size: 14px; font-family: 'PingFang SC'"
          >京ICP备{{ icpBah }}</span
        >
      </span>
      <div class="loginpadding">
        <el-link
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030034"
          target="_blank"
          :underline="false"
        >
          <img
            style="float: left; margin-top: 4px; margin-left: 20px"
            src="../../assets/gongan.png"
            alt=""
          />
          <span>京公网安备 {{ jbwBah }}号</span>
        </el-link>
      </div>
      <span v-show="version">版本:{{ version }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "wrongAuthenticatePage_bottomPage",
  components: {},
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      // 京ICP备案号
      icpBah: "",
      // 京公网备案号
      jbwBah: "",
    };
  },
  created() {
    this.getBah();
  },
  computed: {},
  methods: {
    // 获取 - 京ICP备案号 和 京公网备案号
    getBah() {
      let url = window.location.href; /* 获取完整URL */
      if (url.indexOf("bjsrxx.com") > -1) {
        this.icpBah = "19021711号 -2";
        this.jbwBah = "11010802030034";
      }
      if (url.indexOf("azpt.cn") > -1) {
        this.icpBah = "19021711号 -3";
        this.jbwBah = "11010802039080";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bottomPage {
  width: 100%;
  background: #161B26;
  overflow: hidden;
  height: 350px;
  .shell {
    width: 1440px;
    margin: auto;
    overflow: hidden;
    .shell_1 {
      margin-top: 60px;
      float: left;
      img {
        width: 61px;
        height: 31px;
        margin-bottom: 12px;
      }
      p {
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .shell_2,
    .shell_3,
    .shell_4 {
      float: right;
      width: 100px;
      margin-top: 64px;
      margin-left: 44px;
      img {
        width: 68px;
        height: 68px;
        display: block;
        margin: auto;
        margin-bottom: 14px;
      }
      .p_1 {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        text-align: center;
        margin-bottom: 6px;
      }
      .p_2 {
        font-size: 12px;
        color: #fff;
        text-align: center;
      }
    }
  }
}
@media (max-width: 1500px) {
  .bottomPage .shell{
    width: 1200px;
  }
}
.loginFoot {
  display: flex;
  color: #c0c4cc85;
  justify-content: center;
  padding-top: 10px;
  line-height: 32px !important;
  span {
    font-size: 14px;
    font-family: PingFang SC;
  }
  .loginpadding {
    // padding: 20px 0;
    span {
      color: #c0c4cc85;
      padding-left: 5px;
    }

    .el-link.el-link--default:hover {
      color: #fff;
    }
  }
}
</style>