<template>
  <div id="headPage">
    <div class="headCenter">
      <div class="headLeft">
        <img
          src="../../../src/assets/loginIndex/Logo.png"
          alt=""
          @click="goLogin"
        />
        <el-divider direction="vertical"></el-divider>
        <span>安知职业培训平台</span>
      </div>
      <div class="headRight">
        <div
          class="text"
          :class="$route.path == '/web/login' ? 'homeIndex' : 'homeIndex_1'"
          @click="goLogin"
        >
          首页
        </div>
        <div
          class="text"
          :class="
            $route.path == '/wrongAuthenticatePage/courseCenter'
              ? 'homeIndex_2'
              : 'homeIndex_1'
          "
          @click="onCourseCenter"
        >
          课程中心
        </div>
        <div
          class="text"
         style="padding-left:30px"
          @click="onPersonalLearning"
        >
          个人学习
        </div>
        <div
          class="text"
          :class="
            $route.path == '/wrongAuthenticatePage/aboutUs'
              ? 'homeIndex_2'
              : 'homeIndex_1'
          "
          @click="onAboutUs"
        >
          关于我们
        </div>
<!--        <div-->
<!--          class="text"-->
<!--          :class="-->
<!--            $route.path == '/wrongAuthenticatePage/joinUs'-->
<!--              ? 'homeIndex_2'-->
<!--              : 'homeIndex_1'-->
<!--          "-->
<!--          @click="onJoinUs"-->
<!--        >-->
<!--          商务合作-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wrongAuthenticatePage_headPage",
  data() {
    return {
    };
  },
  created() {},
  methods: {
    // 课程中心
    onCourseCenter() {
        this.$router.push({
        path: "/wrongAuthenticatePage/courseCenter",
        query: {},
      });

      // if (
      //   process.env.NODE_ENV == "development" ||
      //   (process.env.NODE_ENV == "production" &&
      //     process.env.VUE_APP_URL == "development")
      // ) {
      //   window.open("http://192.168.3.112:9000/anzhi-personal-courses/#/");
      // } else {
      //   window.open("https://anzhi.bjsrxx.com/anzhi-personal-courses");
      // }
    },
    // 个人学习
    onPersonalLearning() {
      if (
        process.env.NODE_ENV == "development" ||
        (process.env.NODE_ENV == "production" &&
          process.env.VUE_APP_URL == "development")
      ) {
        window.open(
          "http://192.168.3.112:9000/web-app-new/#/web/login"
        );
      } else {
        window.open(
          "https://anzhi.bjsrxx.com/web-app-new"
        );
      }
      // this.$router.push({
      //   path: "/wrongAuthenticatePage/courseCenter",
      //   query: {},
      // });
    },
    // 跳转关于
    onAboutUs() {
      this.$router.push({
        path: "/wrongAuthenticatePage/aboutUs",
        query: {},
      });
    },
    // 商务合作
    // onJoinUs() {
    //   this.$router.push({
    //     path: "/wrongAuthenticatePage/joinUs",
    //     query: {},
    //   });
    // },
    // 点击logo去首页登录
    goLogin() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="less" scoped>
#headPage {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ffffff;
  .headCenter {
    width: 1168px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .headLeft {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        color: #333;
      }
    }
  }
  img {
    cursor: pointer;
  }
  .headRight {
    display: flex;
    .homeIndex {
      padding-left: 30px;
      color: #2878ff;
      &:after {
        content: "";
        width: 20px;
        height: 1px;
        border-bottom: 3px solid #2878ff;
        display: block;
        margin: 0 auto;
      }
    }
    .homeIndex_1 {
      padding-left: 30px;
    }
    .homeIndex_2 {
      color: #2878ff;
      padding-left:  30px;
      &:after {
        content: "";
        width: 60px;
        height: 1px;
        border-bottom: 3px solid #2878ff;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .text {
    font-size: 17px;
    cursor: pointer;
  }
}
</style>
