<template>
  <!-- 新 - 首页 - 轮播图 -->
  <div class="carousel">
    <el-carousel class="bannerH" loop>
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <el-image
          class="bannerHInner"

          :src="item.picPath"
          @click="toExternal(item.rotationPath)"
          fit="cover"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "wrongAuthenticatePage_carousel",
  components: {},
  data() {
    return {
      bannerList: [],
    };
  },
  created() {
    this.getBanner();
  },
  computed: {},
  methods: {
    // 获取轮播图数据
    getBanner() {
      this.$post("/run/rotation/queryRotationListNew",{terminal:3, rotationPositionId:5}).then((ret) => {
        this.bannerList = ret.data || [];

      });
    },
    // 点击轮播图跳转外部链接
    toExternal(rotationPath) {
      if(rotationPath) {
         window.open(rotationPath);
      }  
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-carousel__indicators--horizontal{
  width: 1440px;
  margin: 0 auto;
  bottom: -26px;
}
@media (max-width: 1500px) {
  /deep/.el-carousel__indicators--horizontal{
    width: 1200px;
  }
}
/deep/.el-carousel__button{
  background-color: #fff;
}
/deep/.el-carousel__indicator.is-active button{
  background-color: #2878FF
}
/deep/.el-carousel__indicator{
  position: relative;
  top: -175px;
}
.bannerH{
  height: 500px;
  /deep/.el-carousel__container{
    height: 100% !important;
    /deep/.el-carousel__item{
      height: 100%;
    }
  }
}
.bannerHInner{
  width: 100%;
  height: 500px;
}
@media (max-width: 1500px) {
  .bannerH{
    height: 415px;
  }
  .bannerHInner{
    height: 415px;
  }
}
</style>